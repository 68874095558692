import 'p5'
import * as p5 from "p5";

class CreaCoding {

    constructor(element, options) {
        this._element = element;
        this._options = {...CreaCoding.options, ...options};

        this._setupPins = this._setupPins.bind(this);

        this.load();

        this.backGroundColour = 'black';
    }

    static get options() {
        return {
            debugMode: false
        };
    }

    load() {
        this._svgNameSpace = 'http://www.w3.org/2000/svg';

        //this._bindEvents();
    }

    _bindEvents() {
        //window.addEventListener('resize', this._setStage);
        //window.addEventListener('load', this._initSequence);
    }


    setup(sketch) {
        this.sketch = sketch;
        sketch.createCanvas(
            document.documentElement.clientWidth,
            document.documentElement.clientHeight,
            sketch.WEBGL
        );
        this.sketch.background(this.backGroundColour);
        this.x = Math.round(sketch.random(sketch.width));
        this.y = Math.round(sketch.random(sketch.height));
        this.xspeed = 10;
        this.yspeed = 3;

        let numPins = 8;
        this.pins = this._setupPins(numPins);
        this.fills = this._getFills();

        console.log('p5 setupped!');
    }

    draw(sketch) {
        this.sketch.background(this.backGroundColour);
        sketch.translate(0,0);

        let locX = sketch.mouseX - sketch.width / 2;
        let locY = sketch.mouseY - sketch.height / 2;
        sketch.pointLight(255, 255, 255, locX, locY, 150);

        this.pins.forEach(pin=>{
            this._drawPin(pin);
        });


        //console.log('p5 drawed!');
    }

    _getFills() {
        return [
            "red",
            "blue",
            "yellow",
            "purple",
            "pink",
            "green",
            "white",
            "orange",
            "lightgreen",
            "lightblue"
        ];
    }

    _setupPins(numPins) {
        let createdPins = [];
        for (let i = 0; i < numPins; i++) {
            createdPins.push(
                {
                    "i": i,
                    "x": Math.round(this.sketch.random(this.sketch.width)),
                    "y": Math.round(this.sketch.random(this.sketch.height)),
                    "z": Math.round(this.sketch.random(0,-200)),
                    "rotate": Math.round(this.sketch.random(-1,1)),
                    "xspeed": Math.round(this.sketch.random(this.xspeed)),
                    "yspeed": Math.round(this.sketch.random(this.yspeed))
                }
            );
        }
        //console.log(`${width}/${height}`)
        //console.log(createdPins);
        return createdPins;
    }

    _drawPin(pin) {
        pin.x = pin.x + pin.xspeed;
        pin.y = pin.y + pin.yspeed;

        for (let n = 0; n < this.pins.length; n++) {
            if (n !== pin.i) {
                //console.log(`${pin.x},${pin.y}`);
                if (this.sketch.int(this.sketch.dist(pin.x, pin.y, this.pins[n].x, this.pins[n].y)) < 55) {
                    //if ((pin.x == this.pins[n].x) && (pin.y == this.pins[n].y)) {
                    console.log('collision!');
                    this.doFlash(this.fills[pin.i]);

                    // bounce pin
                    pin.xspeed = -pin.xspeed;
                    pin.yspeed = -pin.yspeed;

                    // bounce other pin
                    this.pins[n].xspeed = -pin.xspeed;
                    this.pins[n].yspeed = -pin.yspeed;
                }
            }
        }

        if (pin.x + 110 >= this.sketch.width) {
            pin.xspeed = -pin.xspeed;
            pin.x = this.sketch.width - 110;
        } else if (pin.x <= 0) {
            pin.xspeed = -pin.xspeed;
            pin.x = 0;
        }

        if (pin.y + 110 >= this.sketch.height) {
            pin.yspeed = -pin.yspeed;
            pin.y = this.sketch.height - 110;
        } else if (pin.y <= 0) {
            pin.yspeed = -pin.yspeed;
            pin.y = 0;
        }

        this.pins[pin.i] = {
            "i": pin.i,
            "x": pin.x,
            "y": pin.y,
            "z": pin.z,
            "rotate": pin.rotate,
            "xspeed": pin.xspeed,
            "yspeed": pin.yspeed
        };

        //console.log(pin.x);
        //,pin.y,pin.xspeed,pin.yspeed

        this.sketch.push();
        this.sketch.translate(pin.x-this.sketch.width/2, pin.y-this.sketch.height/2, pin.z);
    
            this.sketch.noStroke();
            this.sketch.fill(this.fills[pin.i]);

            this.sketch.push();
                //rotateY(-yspeed-((frameCount/5)));
                //rotateX(-xspeed-(frameCount/8));
                this.sketch.rotateZ((pin.xspeed-this.sketch.frameCount/10)*(pin.rotate?pin.rotate:1));

                this.sketch.translate(0,0);
                    this.sketch.cylinder(50, 110);

                this.sketch.translate(0,-56);
                    this.sketch.sphere(50);

                this.sketch.translate(0,107);
                    this.sketch.sphere(50);
            this.sketch.pop();
        this.sketch.pop();
    }

    doFlash(pinColour) {

        console.log(pinColour);
        this.backGroundColour = pinColour;
        //this.sketch.background('blue');
        //this.sketch.background(pinColour);
        // if (pinColour !== 'black') {
        //     console.log('Back to black');
        //    setTimeout(this.doFlash('black'), 250);
        // }
    }

    destroy() {
    }
}

export default element => {
    console.log('Component mounted on', element);

    const creaCoding = new CreaCoding();
    const thisSketch = ( sketch ) => {
        sketch.setup = () => creaCoding.setup(sketch);
        sketch.draw = () => creaCoding.draw(sketch);
    };

    let myp5 = new p5(thisSketch);
}